import { z } from "zod";
import type { EmailAddress } from "../Email/Email.model";
import type { UserInformation } from "../User.model";
import {
  unfuckDueDate,
  type APIPagedResponse,
  type HalPage,
  type ListRequestModel,
} from "../utils/Api.model";
import {
  deserialiseDate,
  type DeserialiseDates,
  type SerialisedDate,
} from "../utils/Serialise.model";
import type { RiskSummaryViewModel } from "./CaseRisk.model";
import type {
  AssignmentEntry,
  BackendAssignmentEntry,
  BackendBaseHistoryEntry,
  BackendCaseHistoryEntry,
  BackendCaseNoteEntry,
  BackendCaseStatusEntry,
  BackendDueDateEntry,
  CaseHistoryEntry,
  CaseStatusEntry,
  DueDateEntry,
} from "./History.model";
import {
  deserialiseScreenResult,
  type BackendScreenResultViewModel,
  type BffScreenResultViewModel,
} from "./Screen.model";
import { format } from "date-fns";

export type BackendCaseViewModel = {
  id: string;
  caseId: string;
  createdAt: SerialisedDate;
  lastScreeningDate: SerialisedDate;
  screenResults: BackendScreenResultViewModel[];
  caseDetails: Detail[];
  caseNotes: BackendCaseNoteEntry[];
  caseStatus: BackendCaseStatusEntry;
  statusHistory: BackendCaseStatusEntry[];
  assignments: BackendAssignmentEntry[];
  emails: BackendCaseEmail[];
  flags: CaseFlags;
  dueDates: BackendDueDateEntry[];
  // riskData: number[];
  // risk: number;
};

export type BffCaseViewModel = {
  id: string;
  caseId: string;
  createdAt: SerialisedDate;

  flags: CaseFlags;

  lastScreeningDate: SerialisedDate;
  amountOfHits: number;

  caseDetails: Detail[];
  caseStatus: BackendCaseStatusEntryViewModel | undefined;
  assignedTo: string | null;
  emails: unknown[];
  dueDate: SerialisedDate | null;
} & (
  | {
      latestScreeningId: string;
    }
  | { latestScreening: BffScreenResultViewModel }
);
export type CaseViewModel = DeserialiseDates<BffCaseViewModel>;

export function deserialiseCase(obj: BffCaseViewModel) {
  const ret = obj as any as CaseViewModel;
  if (obj.caseStatus)
    (ret.caseStatus as any).createdAt = deserialiseDate(
      obj.caseStatus.createdAt
    );
  if (obj.dueDate) {
    ret.dueDate = deserialiseDate(obj.dueDate);
  }
  ret.createdAt = deserialiseDate(obj.createdAt);
  ret.lastScreeningDate = deserialiseDate(obj.lastScreeningDate);
  if ("latestScreening" in obj) {
    (ret as any).lastScreening = deserialiseScreenResult(obj.latestScreening);
  }
  return ret;
}

export type BackendCaseStatusEntryViewModel = {
  status: CaseStatus;
  metadata?: MetadataValue[];
  createdBy: string;
  createdAt: SerialisedDate;
};

export type CaseStatusReasonViewModel = {
  value: string;
  displayValue: string;
};

export type Detail = {
  source: string;
  key: string;
  value: string;
};

export type CaseFlags = {
  suspectedUndeclaredDg: boolean;
  suspectedMisdeclaredDg: boolean;
  unusualBehaviour: boolean;
  documentAuthenticity: boolean;
};
export type CaseFlag = keyof CaseFlags;

export type BackendCaseEmail = BackendBaseHistoryEntry & {
  recipients: EmailAddress[];
  body: string;
  subject: string;
};
export type BffEmailHistoryEntry = BackendBaseHistoryEntry & {
  recipients: string[];
  body: string;
  type: "email";
};

export const mapEmails = (
  emails: BackendCaseEmail[]
): BffEmailHistoryEntry[] => {
  return emails.map((x) => {
    return {
      recipients: x.recipients.map((x) => x.address),
      body: x.body,
      createdBy: x.createdBy,
      createdAt: x.createdAt as SerialisedDate,
      type: "email",
    };
  });
};

export type CaseStatusSummaryViewModel = {
  status: CaseStatus;
  reason?: CaseStatusReasonViewModel;
  metadata?: MetadataValue[];
  createdBy: string;
  createdAt: SerialisedDate;
};

export type ScreeningSummaryViewModel = {
  amountOfHits: number;
  amountOfHitsDelta: number | null;
  date: SerialisedDate | null;
};

export type BackendCaseSummaryViewModel = {
  id: string;
  // In case the ID and the reference are not the same in future
  caseReference: string;
  riskSummary: RiskSummaryViewModel;
  createdAt: SerialisedDate;
  assignedTo: UserInformation | null;
  flags: CaseFlags;
  lastScreening: ScreeningSummaryViewModel;
  caseStatus: CaseStatusSummaryViewModel | null;
  extraFields: Detail[];
  dueDate: SerialisedDate | null;
};
export type CaseSummaryViewModel =
  DeserialiseDates<BackendCaseSummaryViewModel>;

export function deserialiseCaseSummary(obj: BackendCaseSummaryViewModel) {
  const ret = obj as any as CaseSummaryViewModel;
  if (obj.dueDate) {
    ret.dueDate = deserialiseDate(obj.dueDate);
  }
  ret.createdAt = deserialiseDate(obj.createdAt);
  ret.lastScreening.date = obj.lastScreening.date
    ? deserialiseDate(obj.lastScreening.date)
    : null;
  if (obj.caseStatus?.createdAt && ret.caseStatus?.createdAt) {
    ret.caseStatus.createdAt = deserialiseDate(obj.caseStatus.createdAt);
  }
  return obj as any as CaseSummaryViewModel;
}
export function deserialisePagedCaseSummary(
  res: APIPagedResponse<BackendCaseSummaryViewModel>
) {
  for (let i = 0; i < res.data.length; ++i) {
    (res.data[i] as any) = deserialiseCaseSummary(res.data[i]);
  }
  return res as any as APIPagedResponse<CaseSummaryViewModel>;
}

export type BffCaseHistoryViewModel =
  | BackendCaseHistoryEntry
  | BffScreenResultViewModel
  | BffEmailHistoryEntry;

export type BackendCaseHistoryGetEntry =
  | BackendCaseStatusEntry
  | BackendAssignmentEntry
  | BackendDueDateEntry;
export type CaseHistoryGetEntry =
  | CaseStatusEntry
  | AssignmentEntry
  | DueDateEntry;

export function deserialiseCaseHistoryEntry(
  entry: BackendCaseHistoryEntry
): CaseHistoryEntry {
  (entry as any).createdAt = deserialiseDate(entry.createdAt);
  if ("dueDate" in entry && entry.dueDate !== undefined)
    (entry as any).dueDate = deserialiseDate(entry.dueDate);
  return entry as any;
}

export const dueDate = (
  x: BackendDueDateEntry
): BffCaseHistoryComponentData => {
  let body: string;
  const dueDate = unfuckDueDate(x.dueDate);

  if (!dueDate) body = `${x.createdBy} removed the due date`;
  else {
    const dueDateStr = format(dueDate, "yyyy-MM-dd");
    body = `${x.createdBy} set the due date to ${dueDateStr}`;
  }

  return {
    icon: "mdi-calendar-check",
    body: body,
    title: "Due Date",
    date: x.createdAt as SerialisedDate,
    color: "green",
  };
};

const assignment = (x: BackendAssignmentEntry): BffCaseHistoryComponentData => {
  const themselves = x.assignedTo === x.createdBy;

  let body: string;

  if (!x.assignedTo) body = `${x.createdBy} unassigned this case`;
  else if (themselves) body = `${x.createdBy} self-assigned this case`;
  else body = `${x.createdBy} assigned the case to ${x.assignedTo}`;

  return {
    icon: "mdi-clipboard-check",
    body,
    title: "Assignment",
    date: x.createdAt as SerialisedDate,
    color: "purple",
  };
};

const getColor = (x: { status: CaseStatus }) => {
  if (x.status === CaseStatus.Open) return "green";
  if (x.status === CaseStatus.InProgress) return "orange";
  if (x.status === CaseStatus.Closed) return "red";
  return "green";
};

const getStatusIcon = (x: { status: CaseStatus }) => {
  if (x.status === CaseStatus.Open) return "mdi-check";
  if (x.status === CaseStatus.InProgress) return "mdi-progress-check";
  if (x.status === CaseStatus.Closed) return "mdi-close-box-outline";
  return "mdi-account";
};

const status = (x: BackendCaseStatusEntry): BffCaseHistoryComponentData => {
  return {
    icon: getStatusIcon(x),
    body: `${x.createdBy} changed the status to ${
      CASES_STATUS_NAMES[x.status]
    }`,
    color: getColor(x),
    title: `Status update`,
    date: x.createdAt as SerialisedDate,
    metadata: x.metadata,
  };
};

const caseNote = (x: BackendCaseNoteEntry): BffCaseHistoryComponentData => {
  return {
    icon: "mdi-note-plus-outline",
    body: `${x.createdBy} added a note '${x.text}'`,
    title: "Note added",
    date: x.createdAt as SerialisedDate,
  };
};

const screenResult = (
  x: BffScreenResultViewModel
): BffCaseHistoryComponentData => {
  return {
    icon: "mdi-monitor",
    body: `New screen result created`,
    title: "Screen result",
    date: x.createdAt as SerialisedDate,
    color: "cyan",
    screeningId: x.screeningId,
  };
};

const email = (x: BffEmailHistoryEntry): BffCaseHistoryComponentData => {
  return {
    icon: "mdi-email-outline",
    body: `${x.createdBy} sent an email to ${x.recipients.length} recipients`,
    title: "Email sent",
    date: x.createdAt as SerialisedDate,
    color: "pink",
  };
};

export const mapHistoryItem = (
  x: BffCaseHistoryViewModel
): BffCaseHistoryComponentData => {
  if (x.type === "assignment") return assignment(x);
  else if (x.type === "casenote") return caseNote(x);
  else if (x.type === "status") return status(x);
  else if (x.type === "screenresult") return screenResult(x);
  else if (x.type === "email") return email(x);
  else if (x.type === "dueDate") return dueDate(x);

  const _exhaustiveCheck: never = x;
  return x;
};

export type CaseHistoryItemType = BffCaseHistoryViewModel["type"];

export type BffCaseHistoryComponentData = {
  icon: string;
  body: string;
  title: string;
  date: SerialisedDate;
  color?: string;
  metadata?: MetadataValue[];
  screeningId?: string;
} | null;
export type CaseHistoryComponentData =
  DeserialiseDates<BffCaseHistoryComponentData>;

export function deserialiseCaseHistoryComponentData(
  el: BffCaseHistoryComponentData
) {
  if (!el) return null;
  (el as any).date = deserialiseDate(el.date);
  return el as any as CaseHistoryComponentData;
}

export type CasePage = HalPage<BackendCaseViewModel, "cases">;

export type MetadataValue = {
  key: string;
  value: string;
  displayValue: string;
  dropdownSelection?: MetadataValue;
  child?: {
    key: string;
    value: string;
    displayValue: string;
  };
};

export enum CaseStatus {
  Open = 0,
  InProgress = 1,
  Closed = 2,
}

export const assignmentSchema = z.enum(["unassigned", "mine", "all"]);
export type Assignment = "unassigned" | "mine" | "all";

export const caseListOption = z.enum([
  "WithScreenResults",
  "WithScreenResultsHits",
  "WithScreenResultsMatches",
  "WithCaseDetails",
  "WithCaseStatusHistory",
  "WithAssignment",
  "WithDueDates",
]);
export type CaseListOption = z.infer<typeof caseListOption>;

export type CasesListQueryRequest = ListRequestModel & {
  assignment?: Assignment;
  caseListOptions: CaseListOption[];
};

export const STATUS_ICONS: Record<CaseStatus, string> = {
  [CaseStatus.Open]: "mdi-check-bold",
  [CaseStatus.Closed]: "mdi-progress-close",
  [CaseStatus.InProgress]: "mdi-progress-clock",
};

export const EXTRA_CASE_TABLE_COLUMNS = [
  "New",
  "Rescreening",
  "Is DG",
  "Highest Risk",
  "Date Created",
  "Last Screening Date",
] as const;

export type ExtraCaseTableColumn = (typeof EXTRA_CASE_TABLE_COLUMNS)[number];

export const CASES_STATUS_NAMES: Record<CaseStatus, string> = {
  [CaseStatus.Open]: "Open",
  [CaseStatus.InProgress]: "In Progress",
  [CaseStatus.Closed]: "Closed",
} as const;

export const CASES_STATUS_NAMES_LOOKUP: Record<string, string> = {
  0: "Open",
  1: "In Progress",
  2: "Closed",
} as const;

export type Metadata = Record<string, string>;

export type Links2 = {
  self: Self2;
  setStatus: SetStatus;
  assign: Assign;
};

export type Self2 = {
  href: string;
  method: string;
};

export type SetStatus = {
  href: string;
  method: string;
};

export type Assign = {
  href: string;
  method: string;
};

export const InspectionState = z.enum([
  "Not Requested",
  "Requested",
  // 'Not Required',
  "In Progress",
  "Completed",
  "Failed",
]);
